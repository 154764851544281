<template>
  <div class="preview-box">
    <div class="preview-box-inner">
      <v-icon
        class="no-print print-preview"
        @click="print()"
      >mdi-printer</v-icon>
      <v-icon
        class="no-print close-preview"
        @click="goBack()"
      >mdi-close-circle</v-icon>
      <div class="preview-inner">
        <v-row dense class="mt-0">
          <v-col class="col-8">
            <h2 class="text-center pt-10">CREDIT NOTE</h2>
          </v-col>
          <v-col class="col-4">
            <img width="180px" src="../../assets/logo.png" alt="" />
            <p class="text-small mb-0">
              Riggott &amp; Co Limited<br />
              Unit X, Lodge Lane Industrial Estate<br />
              Tuxford, Newark, Nottinghamshire, NG22 0NL<br />
              Tel: (01777) 872525 Fax: (01777) 872626<br />
              <span class="text-tiny">
                Email: info@riggott.co.uk Web: www.riggott.co.uk
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="col-8">
            <p style="min-height:140px" class="text-mid text-heavy bordered pa-1">
              {{ creditNote.customer }}<br />
              <span v-if="creditNote.customerAddressOne">{{ creditNote.customerAddressOne }}, <br /></span>
              <span v-if="creditNote.customerAddressTwo">{{ creditNote.customerAddressTwo }}, <br /></span>
              <span v-if="creditNote.customerAddressThree">{{ creditNote.customerAddressThree }}, <br /></span>
              <span v-if="creditNote.customerAddressFour">{{ creditNote.customerAddressFour }}, <br /></span>
              <span v-if="creditNote.customerAddressFive">{{ creditNote.customerAddressFive }}, <br /></span>
              <span v-if="creditNote.customerPostcode">{{ creditNote.customerPostcode }}</span>
            </p>
          </v-col>
          <v-col class="col-4">
            <div style="min-height:140px" class="bordered pa-1">
              <v-row class="pa-0 ma-0">
                <v-col class="col-5 pa-0 ma-0">
                  <p class="text-mid pa-0 ma-0">
                    Document no:<br />
                    Date/Tax Point:<br />
                    Reference:<br />
                    <strong>Account Ref:</strong>
                  </p>
                </v-col>
                <v-col class="col-7 pa-0 ma-0">
                  <p class="text-mid pa-0 ma-0 text-right">
                    {{ creditNote.number }}<br />
                    {{ creditNote.date | tinyDate }}<br />
                    {{ creditNote.reference }}<br />
                    <strong>{{ creditNote.accountReference }}</strong>
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <div style="min-height: 137mm">
          <v-row dense>
            <v-col class="col-6 bordered no-right">
              <span class="text-mid text-heavy">
                Description
              </span>
            </v-col>
            <v-col class="col-2 bordered no-right">
              <span class="text-mid text-heavy">
                Qty
              </span>
            </v-col>
            <v-col class="col-2 text-right bordered no-right">
              <span class="text-mid text-heavy">
                Amount
              </span>
            </v-col>
            <v-col class="col-2 text-right bordered">
              <span class="text-mid text-heavy">
                Value
              </span>
            </v-col>
          </v-row>
          <v-row dense v-for="(line, index) in creditNote.lines" :key="index">
            <v-col class="col-6 pt-0 border-left">
              <span class="text-mid">{{ line.description }}</span>
            </v-col>
            <v-col class="col-2 pt-0 border-left">
              <span class="text-mid">{{ line.quantity }}</span>
            </v-col>
            <v-col class="col-2 pt-0 border-left">
              <span class="text-mid">{{ line.price | priceInPounds | currency }}</span>
            </v-col>
            <v-col class="col-2 pt-0 border-left border-right">
              <span class="text-mid">{{ (line.quantity * line.price) | priceInPounds | currency }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="col-6 pt-5 border-left text-mid">
              <div class="unspaced-p" v-html="creditNote.description"></div>
            </v-col>
            <v-col class="col-2 pt-0 border-left">&nbsp;</v-col>
            <v-col class="col-2 pt-0 border-left">&nbsp;</v-col>
            <v-col class="col-2 pt-0 text-right border-left border-right">&nbsp;</v-col>
          </v-row>
          <v-row v-for="n in blankLines" :key="n" dense>
            <v-col class="col-6 pt-0 border-left">&nbsp;</v-col>
            <v-col class="col-2 pt-0 border-left">&nbsp;</v-col>
            <v-col class="col-2 pt-0 border-left">&nbsp;</v-col>
            <v-col class="col-2 pt-0 text-right border-left border-right">&nbsp;</v-col>
          </v-row>
          <v-row dense>
            <v-col class="border-top"></v-col>
          </v-row>
        </div>
        <v-row>
          <v-col class="col-8">
            <p style="min-height:110px" class="text-small text-heavy bordered pa-1 mb-0">
              Delivery location:<br />
              {{ creditNote.siteLocation }}<br />
              Description: {{ creditNote.workDescription }}<br />
              Contact: {{ creditNote.contact  }}
            </p>
          </v-col>
          <v-col class="col-4">
            <p style="min-height:110px" class="text-mid bordered pa-1">
              <span class="text-heavy">Sub-total:</span>
              <span class="float-right text-heavy">
                {{ creditNote.subTotal | priceInPounds | currency }}
              </span><br />
              <span class="text-heavy">VAT:</span>
              <span class="float-right text-heavy">
                {{ creditNote.vat | priceInPounds | currency }}
              </span><br />
              <span class="text-heavy">Total:</span>
              <span class="float-right text-heavy">
                {{ creditNote.total | priceInPounds | currency }}
              </span><br />
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-0" dense>
          <v-col class="col-12 pt-0">
            <p class="text-center text-mid">
              Company registered in England Number 2985947
              VAT Registration Number GB 598 6171 83    UTR 74932 96911<br />
              <img width="700px" src="../../assets/riggott_accreditation_logos.png" alt="" />
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'InvoicesCreditNotePrintView',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      creditNote: {},
      blankLines: 0,
    };
  },
  methods: {
    goBack() {
      this.$router.go('-1');
    },
    print() {
      window.print();
    },
    getCreditNote() {
        axios.get(`/creditNotes/getSingle/${this.$route.params.id}.json?token=${this.token}`)
        .then((response) => {
          this.creditNote = response.data.creditNote;
          const lineNumber = response.data.creditNote.lines.length;
          this.blankLines = 17 - lineNumber;
        });
      },
  },
  mounted() {
    this.getCreditNote();
  },
}
</script>
  
<style lang="scss" scoped>
  @page {
    size: A4;
    margin: 0;
  }
  @media print {
    html, body {
      width: 210mm;
      height: 297mm;
    }
  }
</style>
  